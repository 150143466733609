<i18n src="./translations"></i18n>

<template>
  <client-only>
    <div class="caris-select" :class="{ 'caris-select--disabled': isDisabled }">
      <label
        v-show="!selectedOption && !isFocused"
        class="caris-select__label"
        :for="`caris-select__select#${escapedLabel}`"
      >
        {{ label }}
      </label>
      <select
        :id="`caris-select__select#${escapedLabel}`"
        ref="select"
        v-model="selectedOption"
        class="caris-select__select"
        :class="[
          {
            'caris-select__select--enabled': !selectedOption && !isDisabled,
            'caris-select__select--disabled': isDisabled,
            'caris-select__select--active': selectedOption,
          },
          qaClassName + 'select',
          gtmClassName,
        ]"
        @focus="setFocus"
        @blur="clearFocus"
      >
        <option
          v-if="isManufacturersSelect && isEmptyOptions && !isSafariBrowser()"
          disabled
        >
          {{ $t('caris-select.loading') }}...
        </option>
        <optgroup
          v-for="optionGroup in optionGroups"
          :key="optionGroup.label"
          class="caris-select__option-group"
          :label="optionGroup.label"
        >
          <option
            v-for="option in optionGroup.options"
            :key="option.value"
            class="caris-select__option"
            :value="option"
          >
            {{
              option.label ||
              (option.labels ? option.labels.join(', ') : '-') | removeBrTag
            }}
          </option>
        </optgroup>
      </select>
      <Icon
        class="caris-select__icon"
        name="chevron_right"
        width="18"
        height="18"
      />
    </div>
  </client-only>
</template>

<script>
import Icon from 'Components/00-generated/Icon';

import globalMixin from 'Libs/mixins/globalMixin';
import qaClassNameMixin from 'Libs/mixins/qaClassNameMixin';
import ClientOnly from 'vue-client-only';
import carisSelectionFormMixin from 'Libs/mixins/carisSelectionFormMixin';

export default {
  name: 'CarisSelect',
  components: { Icon, ClientOnly },
  filters: {
    removeBrTag(value) {
      return value.replace('<br>', ' ');
    },
  },
  mixins: [globalMixin, carisSelectionFormMixin, qaClassNameMixin],
  props: {
    selection: {
      type: Object,
      default: () => ({}),
    },
    optionGroups: {
      type: Array,
      required: true,
      default: () => ({}),
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isManufacturersSelect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedOption: this.selection,
      isFocused: false,
    };
  },
  computed: {
    isDisabled() {
      if (this.isManufacturersSelect) return false;
      return this.disabled || this.isEmptyOptions;
    },
    isEmptyOptions() {
      return this.optionGroups && this.optionGroups.length === 0;
    },
    escapedLabel() {
      return this.label.replace(/\s/g, '_');
    },
  },
  watch: {
    selection() {
      this.selectedOption = this.selection;
    },
    selectedOption(option) {
      if (option) {
        this.$emit('change', option);
      }
    },
  },
  mounted() {
    document.addEventListener('mouseup', this.clearFocus);

    if (this.isSafariBrowser()) {
      this.loadManufacturers();
    }
  },
  destroyed() {
    document.removeEventListener('mouseup', this.clearFocus);
  },
  methods: {
    isSafariBrowser() {
      const userAgent = navigator.userAgent;
      return /^((?!chrome|android).)*safari/i.test(userAgent);
    },
    async setFocus() {
      this.isFocused = true;

      if (this.isManufacturersSelect && this.isEmptyOptions) {
        await this.loadManufacturers();
      }
    },
    clearFocus(event) {
      const selectElement = this.$refs.select;
      if (event.target !== selectElement && this.isFocused) {
        if (event !== MouseEvent) {
          selectElement.blur();
        }
        this.isFocused = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import 'variables/breakpoints';
@import 'mixins';

@mixin text-styling {
  font-size: var(--font-size-SM);
  line-height: var(--font-size-M);
  color: var(--color-mad-hippo);
}

.caris-select {
  position: relative;
  width: 100%;

  .caris-select__label,
  .caris-select__icon {
    pointer-events: none;
  }

  &--disabled {
    pointer-events: none;
    cursor: not-allowed;

    &#{&} .caris-select__label {
      color: var(--color-silver-lining);
    }
  }

  .caris-select__label {
    position: absolute;
    left: var(--space-1--half);
    top: var(--space-1);

    @include text-styling;
  }

  .caris-select__select {
    width: 100%;
    padding: var(--space-1) 32px var(--space-1) var(--space-1--half);
    border: none;
    border-radius: 3px;
    appearance: none;
    background: var(--color-white);
    cursor: pointer;

    @include text-styling;

    &::-ms-expand {
      display: none;
    }

    &--active {
      color: var(--color-nero);
    }

    &--disabled {
      & + .caris-select__icon {
        fill: var(--color-silver-lining);
      }
    }
  }

  .caris-select__icon {
    position: absolute;
    right: var(--space-1--half);
    top: var(--space-1);
    transform: rotate(90deg);
    fill: var(--color-secondary);
  }
}
</style>
